import { createApp, defineAsyncComponent } from "vue";
import App from "./App.vue";
import "./index.css";

import router from "./router.js";
import store from "./store/index.js";

import TheSubHeader from "./components/layout/TheSubHeader.vue";

import BaseBadge from "./components/ui/BaseBadge";
import BaseButton from "./components/ui/BaseButton";
import BaseSpinner from "./components/ui/BaseSpinner";
// lazy component loading
const BaseDialog = defineAsyncComponent(() =>
  import("./components/ui/BaseDialog")
);

createApp(App)
  .use(router)
  .use(store)
  .component("sub-header", TheSubHeader)
  .component("badge", BaseBadge)
  .component("base-button", BaseButton)
  .component("base-spinner", BaseSpinner)
  .component("base-dialog", BaseDialog)
  .mount("#app");
