let logoutTimer;

export default {
  state() {
    return {
      userId: null,
      token: null,
      didAutoLogout: false
    };
  },
  getters: {
    token(state) {
      return state.token;
    },
    isLoggedIn(state) {
      return !!state.token;
    },
    didAutoLogout(state){
      return state.didAutoLogout;
    }
  },
  actions: {
    async login(context, payload) {
      const response = await fetch("https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyDxLDmHC1MURsKRUVzXvQO3WkC66Pml5Ps", {
        method: "POST",
        body: JSON.stringify({
          email: payload.email,
          password: payload.password,
          returnSecureToken: true
        })
      })

      const responseData = await response.json();
      
      if(!response.ok) {
        if(response.status == 400) {
          throw new Error("Firebase Error: " + responseData.error.message)
        } else {
          throw new Error(responseData.message || "Login failed");
        }
      }
      const expiresIn = +responseData.expiresIn * 1000;
      const expireDate = new Date().getTime() + expiresIn;

      sessionStorage.setItem("token", responseData.idToken);
      sessionStorage.setItem("userId", responseData.localId);
      sessionStorage.setItem("expireDate", expireDate);

      console.log(sessionStorage.getItem("userId"));
      console.log(expiresIn);

      logoutTimer = setTimeout(function(){
        context.dispatch("autoLogout");
      }, expiresIn);

      console.log(logoutTimer);

      context.commit("setUser", {
        token: responseData.idToken,
        userId: responseData.localId
      });

    },
    autoLogin(context){
      console.log("autologin");
      const token = sessionStorage.getItem("token");
      const userId = sessionStorage.getItem("userId");
      const expireDate = sessionStorage.getItem("expireDate");

      if(token && userId){

        const expiresIn = expireDate - new Date().getTime();

        console.log("expiresIn: " + expiresIn);

        if (expiresIn < 1000) {
          return;
        }

        logoutTimer = setTimeout(function(){
          context.dispatch("autoLogout");
        }, expiresIn);

        context.commit("didAutoLogout", false);

        context.commit("setUser", {
          token: token,
          userId: userId
        });
      }
    },
    logout(context) {
      console.log("logout");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userId");
      sessionStorage.removeItem("expireDate");

      clearTimeout(logoutTimer);

      context.commit("setUser", {
        token: null,
        userId: null
      });
    },
    autoLogout(context) {
      console.log("autoLogout");
      context.dispatch("logout");
      context.commit("didAutoLogout");
    }
  },
  mutations: {
    setUser(state, payload){
      state.token = payload.token,
      state.userId = payload.userId,
      state.didAutoLogout = false
    },
    didAutoLogout(state) {
      state.didAutoLogout = true;
    }
  },
};
