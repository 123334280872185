export default {
  namespaced: true,
  state() {
    return {
      magicians: [{ id: 1, name: "David Blaine" }],
    };
  },
  getters: {
    all(state) {
      return state.effects;
    },
  },
  actions: {},
  mutations: {},
};
