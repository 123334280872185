import { createRouter, createWebHistory } from "vue-router";

// lazy import for pages
const UserAuth = () => import("./pages/UserAuth");
const MagicEffectsList = () => import("./pages/MagicEffectsList");
const MagicEffectDetail = () => import("./pages/MagicEffectDetail");
const NewMagicEffect = () => import("./pages/NewMagicEffect");
const EditMagicEffect = () => import("./pages/EditMagicEffect");
const PageNotFound = () => import("./pages/PageNotFound.vue");

import store from "./store/index.js";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Root",
      redirect: "/magic-effects",
    },
    {
      path: "/login",
      name: "Login",
      component: UserAuth,
    },
    {
      path: "/magic-effects",
      name: "Magic Effects",
      component: MagicEffectsList,
      meta: { protected: true },
    },
    {
      path: "/magic-effects/:id",
      props: true,
      name: "Magic Effect",
      component: MagicEffectDetail,
      meta: { protected: true },
    },
    {
      path: "/magic-effects/new",
      name: "New Magic Effect",
      component: NewMagicEffect,
      meta: { protected: true },
    },
    {
      path: "/magic-effects/:id/edit",
      props: true,
      name: "Edit Magic Effect",
      component: EditMagicEffect,
      meta: { protected: true },
    },
    {
      path: "/:notFound(.*)",
      component: PageNotFound,
    },
  ],
});

router.beforeEach(function(to, _, next) {
  if (to.meta.protected === true && !store.getters.isLoggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
