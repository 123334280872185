<template>
  <svg viewBox="0 0 40 40" class="spinner">
    <circle cx="20" cy="20" r="18"></circle>
  </svg>
</template>

<style scoped>
/* $spinnerSize: 40; */
svg.spinner {
  width: 40px;
  height: 40px;
  /* 
  x: 0px;
  y: 0px;
  viewbox: 0 0 40 40; 
  */
}

svg.spinner circle {
  fill: transparent;
  stroke: indigo;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-dasharray: 125.6; /* (3.14 * 40) */

  transform-origin: 20px 20px 0;
  animation: spinner 4s linear infinite;
}

@keyframes spinner {
  0% {
    stroke-dashoffset: 26.4; /* 0.66 * 40 */
    transform: rotate(0deg);
  }
  50% {
    stroke-dashoffset: 125.6; /* 3.14 * 40 */
    transform: rotate(540deg);
  }
  100% {
    stroke-dashoffset: 26.4; /* 0.66 * 40 */
    transform: rotate(1080deg);
  }
}
</style>
