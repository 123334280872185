<template>
  <div class="flex justify-between pb-1 border-b border-gray-500 mb-4">
    <h2 class="text-xl font-medium self-end">{{ title }}</h2>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>
