<template>
  <the-header></the-header>
  <main class="container mx-auto px-4">
    <router-view></router-view>
  </main>
</template>

<script>
import TheHeader from "./components/layout/TheHeader.vue";

export default {
  components: { TheHeader },
  created() {
    this.$store.dispatch("autoLogin");
  },
  computed: {
    didAutologout() {
      return this.$store.getters.didAutologout;
    },
  },
  watch: {
    didAutologout(newValue, oldValue) {
      if (newValue === true && oldValue != newValue) {
        this.$router.replace("/login");
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@200;300;500;600&display=swap");

@font-face {
  font-family: "Yanone Kaffeesatz";
}
</style>
