function baseUrl(context, id = null) {
  let url = context.getters.dbUrl;
  url = id ? `${url}/${id}/.json` : `${url}.json`;
  return url + "?auth=" + context.rootGetters.token;
}

export default {
  namespaced: true,
  state() {
    return {
      dbUrl:
        "https://magic-effects-2d35a-default-rtdb.europe-west1.firebasedatabase.app/effects",
      lastFetched: null,
      filters: [],
      tags: [
        "street-magic",
        "cards",
        "force",
        "control",
        "palm",
        "transposition",
        "gimmick",
        "cardistry",
        "card-flourish",
        "mentalism",
      ],
      methods: ["Marlo Tilt", "Double Lift"],
      effects: [],
      currentEffect: null,
    };
  },
  getters: {
    all(state) {
      return state.effects;
    },
    filtered(state) {
      if (state.filters.length == 0) {
        return state.effects;
      } else {
        return state.effects.filter((effect) => {
          return state.filters.some((filter) => {
            return effect.tags.some((tag) => {
              return tag === filter;
            });
          });
        });
      }
    },
    currentEffect(state) {
      return state.currentEffect;
    },
    filters(state) {
      return state.filters;
    },
    tags(state) {
      return state.tags;
    },
    methods(state) {
      return state.methods;
    },
    dbUrl(state) {
      return state.dbUrl;
    },
    shouldUpdate(state) {
      // should cache data for a minute
      const now = new Date().getTime();
      return (now - state.lastFetched) / 1000 > 60;
    },
  },
  mutations: {
    addFilter(state, filter) {
      if (!state.filters.includes(filter)) {
        state.filters.push(filter);
      }
    },
    removeFilter(state, filter) {
      if (state.filters.includes(filter)) {
        state.filters = state.filters.filter((item) => {
          return filter != item;
        });
      }
    },
    create(state, data) {
      state.effects.push(data);
    },
    update(state, data) {
      state.currentEffect = data;
      state.effects = state.effects.map((effect) => {
        return effect.id === data.id ? data : effect;
      });
    },
    setEffects(state, effects) {
      state.effects = effects;
    },
    setCurrentEffect(state, effect) {
      state.currentEffect = effect;
    },
    setLastFetched(state) {
      state.lastFetched = new Date().getTime();
    },
  },
  actions: {
    addFilter(context, filter) {
      context.commit("addFilter", filter);
    },
    removeFilter(context, filter) {
      context.commit("removeFilter", filter);
    },
    create(context, data) {
      fetch(baseUrl(context), { method: "POST", body: JSON.stringify(data) })
        .then((response) => response.json())
        .then((db_data) => {
          console.log(db_data);
          const saved_data = {
            ...data,
            id: db_data["name"],
          };
          context.commit("create", saved_data);
        });
    },
    update(context, data) {
      fetch(baseUrl(context, data.id), {
        method: "PATCH",
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((db_data) => {
          console.log(db_data);
          context.commit("update", data);
        });
    },
    setCurrentEffect(context, id) {
      context.commit(
        "setCurrentEffect",
        context.getters.all.find((effect) => effect.id === id)
      );
    },
    async load(context) {
      if (!context.getters.shouldUpdate) return;

      const response = await fetch(baseUrl(context));
      const data = await response.json();

      if (!response.ok) {
        if (response.status == 401) {
          context.dispatch("logout");
        }
        const error = new Error(data.message);
        throw error;
      }

      let effects = [];
      for (const key in data) {
        const effect = {
          id: key,
          name: data[key].name,
          magician_id: data[key].magician_id,
          description: data[key].description,
          instructions: data[key].instructions,
          tags: data[key].tags,
          methods: data[key].methods,
          video_links: data[key].video_links,
        };
        effects.push(effect);
      }
      context.commit("setEffects", effects);
      context.commit("setLastFetched");
    },
  },
};
