<template>
  <header
    class="flex items-center justify-between h-20 px-4 bg-purple-900 shadow-lg mb-4"
  >
    <router-link to="/">
      <h1 class="text-2xl font-extrabold">Magic Effects</h1>
    </router-link>
    <nav>
      <ul class="flex justify-between space-x-3">
        <li><router-link to="/magic-effects">Effects</router-link></li>
        <li><router-link to="/magicians">Magicians</router-link></li>
        <li><a href="#" @click="logout">Logout</a></li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style scoped>
a.router-link-active {
  text-decoration: underline;
}
</style>
