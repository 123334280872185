import { createStore } from "vuex";

import auth from './auth.js';
import effects from "./effects.js";
import magicians from "./magicians.js";

export default createStore({
  modules: {
    effects,
    magicians,
    auth
  },
});
